import React, { useState, useEffect } from "react";
import FormComponent from "./FormComponent";
import { BrowserRouter as Router, useHistory, useParams } from "react-router-dom";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";
import { validatTasting } from "./TastingValidator";
import Config from "../../../../../../assets/Config";

const FormEngine = ({ strings, config }) => {
    let { id } = useParams();
    let { type } = useParams();
    let history = useHistory();
    let ba = new BackendAccessor();

    const [tasting, setTasting] = useState(null);
    const [products, setProducts] = useState(null);
    const [assessmentSheets, setAssessmentSheets] = useState(null);
    const [loadingText, setLoadingText] = useState(null);

    const loadTasting = () => {
        ba.getTastingById(
            id,
            (tasting) => {
                setTasting(tasting);
                console.debug("Tasting", tasting);
            },
            (error) => {
                console.error("Error loading tasting: ", error);
                config.popupAlert("Error", "Error loading tasting");
            }
        )
    }

    const createTasting = () => {
        console.log("Local tasting created");
        setTasting({
            type: type == "podcast" ? "PODCAST_TAST" : "CONVENTIONAL_TAST",
        });
    }

    const loadProducts = () => {
        ba.getProductsList(
            (products) => {
                let productsMap = {};
                products.forEach(product => {
                    productsMap[product.reference] = product;
                });

                setProducts(productsMap);
                console.debug("Products", productsMap);
            },
            (error) => {
                console.error("Error loading products: ", error);
                config.popupAlert("Error", "Error loading products");
            }
        )
    }

    const loadAssessmentSheets = () => {
        ba.getPublicAssessmentSheets((result) => {
            setAssessmentSheets(result);
            console.debug("Public assessment sheets:", result);

            ba.getPrivateAssessmentSheets((result) => {
                console.debug("Private assessment sheets:", result);
                Object.entries(result).forEach(([id, assessmentSheet]) => {
                    setAssessmentSheets((prevState) => ({
                        ...prevState,
                        [assessmentSheet.product]: {
                            ...prevState[assessmentSheet.product],
                            "sheets": {
                                ...prevState[assessmentSheet.product]?.sheets,
                                [id]: assessmentSheet
                            }
                        }
                    }));
                });

            }, (error) => {
                console.error("Error loading private assessmentSheets: ", error);
                config.popupAlert("Error", "Error private assessmentSheets");
            });
        }, (error) => {
            console.error("Error loading public assessmentSheets: ", error);
            config.popupAlert("Error", "Error private assessmentSheets");
        });
    };

    const getPublicLink = () => {
        console.log(config);
        let isDev = Config.ENV == "DEV";
        let url = isDev ? "https://dev.smarttasting.net/" : "https://www.smarttasting.net/";
        url += "publicTasting/" + config?.user?.uid + "/" + id;
        return url;
    }

    useEffect(() => {
        if (tasting == null) {
            if (id == null) {
                createTasting();
            } else {
                loadTasting();
            }
        }
        if (products == null) {
            loadProducts();
        }
        if (assessmentSheets == null) {
            loadAssessmentSheets();
        }
    }, [strings, id]);

    const confirmAction = () => {
        console.info("Trying to update tasting", tasting);

        setLoadingText(strings.savingTasting);
        let error = validatTasting(strings, tasting);
        if (error != null) {
            setLoadingText(null);
            config.popupAlert(strings.error, error);
        } else {
            if (id == null) {
                ba.createTasting(tasting, onSuccess, onError);
            } else {
                ba.updateTastingById(id, tasting, onSuccess, onError);
            }
        }
    }

    const onSuccess = (data) => {
        setLoadingText(null);
        console.info("Tasting updated/created successfully: ", data);
        config.update("modificated", false);
        config.popupAlert(strings.success, strings.successMsg, () => {
            history.replace(`/dashboard/tastings/${type}/list`);
        });
    };

    const onError = (error) => {
        setLoadingText(null);
        console.error("Error updating tasting", error);
        config.popupAlert(strings.error, "Ha ocurrido un error");
    }

    const cancelAction = () => {
        config.isModificated(() => {
            history.replace(`/dashboard/tastings/${type}/list`);
        });
    };

    const setTastingFiltered = (input) => {
        if (!config.modificated) {
            config.update("modificated", true);
        }
        setTasting(input);
    };

    const isOfflineAllowed = () => {
        return (type === "podcast" && (config?.plan?.wineTourismAddon || config?.cellar?.billing?.wineTourismAddon));
    };

    const isReady = () => {
        return tasting != null && products != null && assessmentSheets != null;
    }

    return (
        <>
            {isReady() ? (
                <FormComponent
                    config={config}
                    strings={strings}
                    tasting={tasting}
                    products={products}
                    assessmentSheets={assessmentSheets}
                    setTasting={setTastingFiltered}
                    type={type}
                    getPublicLink={getPublicLink}
                    confirmAction={confirmAction}
                    cancelAction={cancelAction}
                    offlineAllowed={isOfflineAllowed()}
                />
            ) : (
                <LoadingScreen />
            )}
            {loadingText != null ? <DownloadingPopup strings={strings} headerText={loadingText} /> : <></>}
        </>
    );
};

export default FormEngine;
