import React, { useState, useEffect } from "react";
import FormComponent from "./FormComponent";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import BackendAccessor from "../../../../../../assets/BackendAccessor";
import Config from "../../../../../../assets/Config";
import { BrowserRouter as Router, useParams, useHistory } from "react-router-dom";
import { copyTextToClipBoard } from "../../../../../../assets/utils/DeviceUtils";
import { validateEvent, validateCode } from "./EventValidator";
import * as moment from "moment";

const FormEngine = ({ strings, config }) => {
  let { id } = useParams();
  let { type } = useParams();
  let history = useHistory();
  let ba = new BackendAccessor();

  const [event, setEvent] = useState(null);
  const [tastings, setTastings] = useState(null);
  const [instructors, setInstructors] = useState(null);
  const [editingCode, setEditingCode] = useState(false);
  const [code, setCode] = useState(null);

  const types = {
    live: "CONVENTIONAL_TAST",
    podcast: "PODCAST_TAST"
  }

  const generationReportTypes = {
    notSelected: strings.notSelected,
    ON_FINISH_EVENT: strings.onFinishEvent,
    PERIODIC: strings.periodic,
  }

  const periodicIntervals = {
    notSelected: strings.notSelected,
    EVERY_DAY: strings.every + " " + strings.day,
    EVERY_3_DAYS: strings.every + " 3 " + strings.days,
    EVERY_WEEK: strings.every + " " + strings.week,
    EVERY_2_WEEKS: strings.every + " 2 " + strings.weeks,
    EVERY_MONTH: strings.every + " " + strings.month,
    EVERY_3_MONTHS: strings.every + " 3 " + strings.months,
    EVERY_6_MONTHS: strings.every + " 6 " + strings.months,
    EVERY_YEAR: strings.every + " " + strings.year,
  }

  const loadEvent = () => {
    ba.getEventById(
      id,
      (event) => {
        event.id = id;
        console.debug("Event: ", event);
        setEvent(event);
        setCode(event?.sessionCode?.publicCode);
        document.getElementById("publicCode").value = event?.sessionCode?.publicCode;
      },
      (error) => {
        console.error("Error loading event: ", error);
        config.popupAlert("Error", "Error loading event");
      }
    )
  };

  const createEvent = () => {
    ba.generateSessioCodeFree(
      (response) => {
        setEvent({
          type: types[type],
          sessionCode: {
            publicCode: response.publicCode
          }
        });
      },
      (error) => {
        console.error("Error generating code", error);
        setEvent({
          type: types[type],
        });
      }
    );
  }

  const loadTastings = () => {
    ba.getTastingsList(
      types[type],
      (tastings) => {
        let fomated = { notSelected: strings.notSelected };
        tastings.forEach(tasting => {
          fomated[tasting.reference] = tasting?.name || "-";
        });
        setTastings(fomated);
      },
      (error) => {
        console.error("Error loading tastings: ", error);
        config.popupAlert("Error", "Error loading tastings");
      }
    )
  };

  const loadInstructors = () => {
    ba.getInstructorsList(
      (instructors) => {
        let fomated = { notSelected: strings.notSelected };

        if (instructors.length === 0) {
          fomated["addElement"] = "+ " + strings.addInstructor;
        }
        instructors.forEach(instructor => {
          fomated[instructor.reference] = instructor?.name || "-";
        });

        setInstructors(fomated);
      },
      (error) => {
        console.error("Error loading instructors: ", error);
        config.popupAlert("Error", "Error loading instructors");
      }
    );
  };

  useEffect(() => {
    if (event == null) {
      if (id == null) {
        createEvent();
      } else {
        loadEvent();
      }
    }
    if (tastings == null) {
      loadTastings();
    }
    if (instructors == null) {
      loadInstructors();
    }
  }, [strings]);

  useEffect(() => {
    let fromDate = event?.sessionCode?.fromDate;
    let toDate = event?.sessionCode?.toDate;

    if (fromDate == null) {
      fromDate = new Date(new Date().getTime() + 2 * 60 * 1000);
    }

    if (type === "live") {
      toDate = moment(fromDate).add(4, "h").toDate();
    } else if (toDate === null || toDate === undefined) {
      toDate = moment(fromDate).add(1, "year").toDate();
    }

    if (event?.sessionCode?.fromDate != fromDate) {
      updateCode("fromDate", fromDate);
    }

    if (event?.sessionCode?.toDate != toDate) {
      updateCode("toDate", toDate);
    }
  }, [event?.sessionCode?.fromDate]);

  const acceptAction = () => {
    let code = document.getElementById("publicCode").value.toUpperCase();

    config.showDownloadingPopup("Guardando...");
    validateEvent(strings, code, event,
      () => {
        if (id == null) {
          console.log("Trying to create event", event);
          ba.createEvent(event, onSuccess, onError);
        } else {
          console.log("Trying to update event", event);
          ba.updateEvent(id, event, onSuccess, onError);
        }
      },
      (error) => {
        config.hideDownloadingPopup();
        config.popupAlert(strings.error, error);
      });
  };

  const onSuccess = () => {
    config.hideDownloadingPopup();
    config.update("modificated", false);
    config.popupAlert(strings.success, strings.successMsg, () => {
      history.replace("/dashboard/events/" + type + "/list");
    });
  };

  const onError = (error) => {
    config.hideDownloadingPopup();
    console.error("Error uploading data to server: ", error);
    if (error?.error_msg === "STRIPE_ADDITIONAL_EVENTS_NOT_AVAILABLE") {
      config.popupAlert(strings.error, "No se ha encontrado ninguna suscripción activa para añadir el evento adicional.");
    } else {
      config.popupAlert(strings.error, "No se ha podido guardar los datos. Intentelo mas tarde.");
    }
  }

  const cancelAction = () => {
    config.isModificated(() => {
      history.replace("/dashboard/events/" + type + "/list");
    });
  };

  const editCode = () => {
    if (config?.plan?.customCodes) {
      if (editingCode) {
        let tmpCode = document.getElementById("publicCode").value.toUpperCase();
        if (code !== tmpCode) {

          let onFreeCode = () => {
            config.hideDownloadingPopup();
            updateCode("publicCode", tmpCode);
          }

          let onErrorCode = (error) => {
            config.hideDownloadingPopup();
            config.popupAlert(strings.error, error);
            document.getElementById("publicCode").value = event?.sessionCode?.publicCode;
          }

          config.showDownloadingPopup(strings.checkingCode);
          validateCode(strings, tmpCode, onFreeCode, onErrorCode, onErrorCode);
        }
      }

      setEditingCode(!editingCode);
    } else {
      config.popupAlert(strings.error, strings.errorPlanCode);
    }
  };

  const getURL = (includeSteamingTag) => {
    let isDev = Config.ENV == "DEV";
    let code = event?.sessionCode?.publicCode;
    let url = isDev ? "https://dev.smarttasting.net/" + code : "https://www.smarttasting.net/" + code;

    let params = "";

    if (event.lang && event.lang != "notSelected") {
      params = "?lang=" + event.lang.toLowerCase();
    }
    if (event.liteMode) {
      if (params === "") {
        params = "?lite=true";
      } else {
        params += "&lite=true";
      }
    }
    if (includeSteamingTag && event.noStreamingUrlEnabled) {
      if (params === "") {
        params = "?streaming=false";
      } else {
        params += "&streaming=false";
      }
    }
    return url + params;
  };

  const copyLinkToClipBoard = (includeSteamingTag) => {
    let url = getURL(includeSteamingTag);
    copyTextToClipBoard(url, strings, config);
  };

  const setEventFiltered = (input) => {
    if (!config.modificated) {
      config.update("modificated", true);
    }
    setEvent(input);
  };

  const updateEvent = (key, value) => {
    if (key === "instructorPath" && value === "addElement") {
      config.isModificated(() => {
        history.replace("/dashboard/instructors/new");
      });
      return;
    }
    setEventFiltered((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateCode = (key, value) => {
    setEventFiltered((prevState) => ({
      ...prevState,
      sessionCode: {
        ...prevState?.sessionCode,
        [key]: value,
      }
    }));
  };

  return (
    <>
      {event != null && tastings != null && instructors != null ? (
        <FormComponent
          strings={strings}
          config={config}
          type={type}
          event={event}
          tastings={tastings}
          instructors={instructors}
          generationReportTypes={generationReportTypes}
          periodicIntervals={periodicIntervals}
          updateEvent={updateEvent}
          updateCode={updateCode}
          editCode={editCode}
          editingCode={editingCode}
          confirmAction={acceptAction}
          cancelAction={cancelAction}
          copyLinkToClipBoard={copyLinkToClipBoard}
          getURL={getURL}
        />
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};
export default FormEngine;
