import React from "react";
import styled from "@emotion/styled";
import background from "../../../../assets/images/background-login.png";
import TextInput from "../../../shared/TextInput";
import Button from "../../../shared/Button";
import Select from "../../../shared/Select";
import Logo from "../../../../assets/images/logo-full.png";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const SignupComponent = ({ strings, signUpEvent, backLogin, paises, mentorCode }) => {
  const root = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    flexDirection: "column",
    justifyContent: "center",
  };

  const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.98);
  width: 50%;
  margin: 2vh auto;
  padding: 1% 1% 1% 2%;
  border-radius: 3vh;
  @media (max-width: 426px) {
    margin: auto;
    width: 85%;
    height: 90%;
    overflow-x: auto;
  }
  `;

  const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
  `;

  const LogoStyle = styled.img`
    width: 8vw;
    @media (max-width: 426px) {
      width: 30vw;

    }

  `;

  const TermsContainer = {
    margin: "1.5vh 0",
  };

  const TermsText = {
    fontSize: "1.8vh",
    margin: "1vh",
    textAlign: "justify",
    textJustify: "inter-word",
  };

  const FormRow = styled.div`
    display: flex;
    @media (max-width: 426px) {
      flex-direction: column;
    }
  `;

  const OnSuccessContainer = {
    backgroundColor: "rgba(255, 255, 255, 0.98)",
    width: "50%",
    margin: "2vh auto",
    padding: "1% 1% 1% 2%",
    borderRadius: "3vh",
    width: "60%",
    display: "none",
  };

  const SuccessContent = {
    textAlign: "center",
    margin: "2vh 5vw",
    fontSize: "2.2vh",
  };

  const HeaderContainer = {
    color: "#000",
    marginBottom: "2vh",
    textAlign: "center",
    padding: "1vh",
  };

  const HeaderText = {
    fontSize: "3vh",
    fontWeight: "600",
    padding: "2vh",
  };

  const ErrorMsg = styled.div`
    color: red;
    visibility: hidden;
    font-size: 0.8rem;
  `;

  const Link = {
    textDecoration: "underline",
  };

  const ConfirmButton = {
    width: "90%",
  };

  const HintContainer = {
    width: "55%",
    margin: "2vh auto 3vh",
    color: "#000",
    marginTop: "2vh",
    borderRadius: "2vh",
    textAlign: "center",
  };

  const HintText = {
    fontSize: "1.5vh",
  };



  const useStyles = makeStyles((theme) => ({
    customWidth: {
      maxWidth: 500,
    },
  }));
  const classes = useStyles();

  return (
    <div style={root}>
      <Container>
        {/* --- Header ---*/}

        <div id="formSign">
          <div style={HeaderContainer}>
            <a href="https://www.smarttasting.net" target="_blank">
              <LogoStyle src={Logo} />
            </a>
            <h1 style={HeaderText} id={"tittleSign"}>
              Date de alta gratis en SmartTastingSoftware
            </h1>
          </div>

          <FormContainer>
            <div style={{ margin: "auto" }}>
              <form autocomplete="off">
                <FormRow>
                  <div style={{ flex: "1" }}>
                    <div>Email *</div>
                    <TextInput id="email" />
                    <ErrorMsg id="emailError">Este correo esta repetido</ErrorMsg>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div>Nombre de la Empresa *</div>
                    <TextInput id="username" />
                    <ErrorMsg id="usernameError">Es obligatorio poner un nombre</ErrorMsg>
                  </div>
                </FormRow>
                <FormRow>
                  <div style={{ flex: "1" }}>
                    <div>Contraseña *</div>
                    <TextInput id="password" type="password" />
                    <ErrorMsg id="passwordError">Mínimo 6 caracteres minimo</ErrorMsg>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div>Repita su contraseña *</div>
                    <TextInput id="repPassword" type="password" />
                    <ErrorMsg id="repPasswordError">Las contraseñas deben ser iguales</ErrorMsg>
                  </div>
                </FormRow>
                <FormRow>
                  <div style={{ flex: "1" }}>
                    <div>Teléfono</div>
                    <TextInput id="phone" />
                    <ErrorMsg id="repPhoneError">Indica un numero de telefono valido</ErrorMsg>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div>País</div>
                    <Select id="country" options={paises} defaultOption={70} />
                  </div>
                </FormRow>
                <FormRow>
                  <div style={{ flex: "1" }}>
                    <div>Código de mentor: (Opcional)</div>
                    <TextInput id="mentorCode" value={mentorCode} />
                    <ErrorMsg id="mentorCodeError">Introduzca un código de mentor valido</ErrorMsg>
                  </div>
                  <div style={{ flex: "1", margin: "auto" }}>
                    <div style={{ maxWidth: "95%" }}>
                      Si tienes un código de mentor, introdúcelo y tendrás un 5% de descuento en las tarifas.
                    </div>
                  </div>
                </FormRow>

                <div style={TermsContainer}>
                  <input type="checkbox" id={"checkTC"} value={"checkboxTC"} />
                  <label for={"checkTC"}>
                    {"Si, acepto las la "}
                    <a style={Link} target="_new" href={"https://smarttasting.info/terminos-y-condiciones/"}>
                      condiciones de uso
                    </a>
                    {" y la "}
                    <a style={Link} target="_new" href={"https://smarttasting.info/terminos-y-condiciones/"}>
                      política de privacidad
                    </a>
                    {" de SmartTasting Software."}
                  </label>
                  <ErrorMsg id="repCheckError">Debes de aceptar los términos y condiciones</ErrorMsg>
                </div>

              </form>
              <Button value="Darse de alta gratis" action={signUpEvent} style={ConfirmButton} />
            </div>
          </FormContainer>

          {/* --- Hint --- */}
          <div style={HintContainer}>
            <h1 style={HintText}>
              Recuerda que, para acceder al editor, debes confirmar tu dirección de correo pulsando sobre el enlace que te hemos enviado al darte de alta
            </h1>
          </div>
        </div>
      </Container>

      <div id={"formOK"} style={OnSuccessContainer}>
        <div style={HeaderContainer}>
          <LogoStyle src={Logo} />
          <h1 style={HeaderText} id={"tittleSign"}>
            ¡Usuario creado con exito!
          </h1>
        </div>

        <div style={SuccessContent}>
          Te hemos enviado un correo de verificación. Actíva tu usuario desde tu correo electronico.
          <div>
            <Button value="Aceptar" action={backLogin} style={{ margin: "3vh 5vw" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupComponent;
