import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import Button from "../../../../shared/Button";
import Colors from "../../../../../assets/Colors.json";
import ic_facebook from "../../../../../assets/images/ic_facebook.png";
import ic_instagram from "../../../../../assets/images/ic_instagram.png";
import ic_linkedin from "../../../../../assets/images/ic_linkedin.png";
import ic_twitter from "../../../../../assets/images/ic_twitter.png";
import ic_youtube from "../../../../../assets/images/ic_youtube.png";
import LogoFull from "../../../../../assets/images/logo-full.png";

const Subheader = styled.div`
  margin: 1.5rem 0.5rem 1rem 0rem;
  font-weight: bold;
  font-size: 2.5vh;
  color: ${Colors.lightTheme.accentColor1};
  padding-bottom: 0.5rem;
  border-bottom: 1px solid gray;
  width: 75%;
`;

const Tag = styled.div`
  margin: 0.75rem 1rem 0.75rem 0rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
  display: table;
`;

const Column = styled.div`
  padding: 0vh 1vw;
  display: table-cell;
  vertical-align: middle;
`;

const Title = styled.div`
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 2vh 5vh;
`;

const Line = styled.div`
  color: black;
  margin: 1.5vh 0vh;
`;

const Icon = styled.img`
  height: 3vh;
  margin: 1vh;
`;

const rrssStyle = {
  backgroundColor: "black",
  borderRadius: "6px",
  margin: "2vh 15%",
};

const AboutForm = ({ strings, version, mentor }) => {
  return (
    <Container>
      <Title>{strings.about}</Title>
      <Wrapper>
        <Column style={{ textAlign: "center" }}>
          <img src={LogoFull} style={{ height: "13vh", paddingBottom: "1vh" }} />

          <Line style={{ fontWeight: "bold" }}>
            {strings.version} {version}
          </Line>

          {/*<div style={rrssStyle}>
            <a href="https://www.facebook.com/Upwine-108380727661264">
              <Icon src={ic_facebook} />
            </a>
            <a href="https://twitter.com/UpWine_ES">
              <Icon src={ic_twitter} />
            </a>
            <a href="https://www.instagram.com/upwine_app/">
              <Icon src={ic_instagram} />
            </a>
            <a href="https://www.youtube.com/channel/UCZ7WofnWFhKjO4fSvwdsVVA">
              <Icon src={ic_youtube} />
            </a>
            <a href="https://www.linkedin.com/company/upwine/?originalSubdomain=es">
              <Icon src={ic_linkedin} />
            </a>
          </div>*/}
        </Column>

        <Column class="column2">
          {mentor ? (
            <>
              <Subheader>{strings.myAgent}</Subheader>
              <Tag>
                {strings.name}: <b>{mentor.completeName}</b>
              </Tag>
              <Tag>
                {strings.code}: <b>{mentor.publicCode}</b>
              </Tag>
              <Tag>
                {strings.email}: <b>{mentor.email}</b>
              </Tag>
              <Tag>
                {strings.phone}: <b>{mentor.phone}</b>
              </Tag>
            </>
          ) : (
            <></>
          )}

          <Subheader>{strings.about}</Subheader>
          <Line>
            <a href="https://enoturismo-360.com/">Enoturismo 360, S.L.</a>
          </Line>
          <Line>C/ Cyesa 8, 3ºH</Line>
          <Line>28017 Madrid</Line>
          <Line>
            <a href="mailto:hola@smarttasting.info">hola@smarttasting.info</a>
          </Line>
        </Column>
      </Wrapper>
    </Container>
  );
};

export default AboutForm;
