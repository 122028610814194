import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ToolbarComponent from "./ToolbarComponent";
import Firebase from "../../../../assets/Firebase";
import LanguagePopup from "./LanguagePopup";

const ToolbarEngine = ({ strings, setLang, config }) => {
  const [langPopupVisible, setLanPopupVisible] = useState(false);

  let f = new Firebase();
  let history = useHistory();

  const logOut = () => {
    f.tryLogout();
  };

  const goToUpTasting = () => {
    window.location.href = "https://www.smarttasting.net";
  };

  const goToPlans = () => {
    history.push("/dashboard/cellar/plan");
  };

  const selectLang = () => {
    setLanPopupVisible(true);
  };

  return (
    <React.Fragment>
      <LanguagePopup strings={strings} visible={langPopupVisible} setVisible={setLanPopupVisible} setLanguage={setLang} />
      <ToolbarComponent strings={strings} logOut={logOut} config={config} selectLang={selectLang} goToUpTasting={goToUpTasting} goToPlans={goToPlans} />
    </React.Fragment>
  );
};

export default ToolbarEngine;
